export default {
    steps : [
   
       {
         index: 1,
         txt: "Coordonnées",
         txt_en: "Contact"
       },
       {
         index: 2,
         txt: "Paiement",
         txt_en: "Payment"
       },
    ]
}